var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multiple-product-list" },
    _vm._l(_vm.filteredItems, function (item) {
      return _c(
        "div",
        {
          key: item.id || item.name,
          staticClass: "multiple-product-selection",
        },
        [
          _c(
            "div",
            {
              staticClass: "main",
              on: {
                click: function ($event) {
                  return _vm.$emit("chosen", item)
                },
              },
            },
            [
              _c("div", { staticClass: "main-left" }, [
                _c(
                  "div",
                  { staticClass: "name" },
                  [
                    _vm._v("\n          " + _vm._s(item.name) + "\n          "),
                    _vm.isTrial(item)
                      ? _c("b-badge", { staticClass: "badge-success" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.trialText(item)) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                item.description
                  ? _c("div", { staticClass: "description" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.description) + "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "main-right" }, [
                _c(
                  "a",
                  { staticClass: "choice" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.buttonText) + "\n          "
                    ),
                    _c("fa-icon", { attrs: { icon: "chevron-right" } }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }