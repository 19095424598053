<template>
  <div class="multiple-product-list">
    <div
      v-for="item in filteredItems"
      :key="item.id || item.name"
      class="multiple-product-selection"
    >
      <div class="main" @click="$emit('chosen', item)">
        <div class="main-left">
          <div class="name">
            {{ item.name }}
            <b-badge v-if="isTrial(item)" class="badge-success">
              {{ trialText(item) }}
            </b-badge>
          </div>
          <div v-if="item.description" class="description">
            {{ item.description }}
          </div>
        </div>
        <div class="main-right">
          <a class="choice">
            {{ buttonText }}
            <fa-icon icon="chevron-right" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SelectionCards',
  props: {
    items: Array,
    buttonText: {
      type: String,
      default: 'View',
    },
  },
  data() {
    return {
      filteredItems: [],
    }
  },
  computed: {
    ...mapGetters('creditCardProcessing', ['merchantServiceEligibility']),
    ...mapGetters('lawOnCall', [
      'lawOnCallTrialEligible',
      'offerLawOnCall',
    ]),
    ...mapGetters('monitoring', [
      'monitoringServiceActive',
    ]),
    ...mapGetters('products', ['productCategories']),
    ...mapGetters('companies', {
      company: 'currentCompany',
    }),
  },
  watch: {
    items: {
      async handler() {
        this.filteredItems = await this.filterItems()
      },
    },
  },
  async mounted() {
    this.filteredItems = await this.filterItems()
    if (this.company) {
      await this.getEligibilityForCreditCardProcessing({ companyId: this.company.id })
    }
  },
  methods: {
    ...mapActions('creditCardProcessing', ['getEligibilityForCreditCardProcessing']),
    ...mapActions('products', ['getProductCategories']),
    hideMerchantServices(item) {
      if(item?.category === 'merchant-service') {
        return this.merchantServiceEligibility
      } else {
        return true
      }
    },
    async hideLawOnCall(item) {
      // TODO this list may become more extensive and need to be abstracted out to store
      switch (item.name) {
        case 'Law on Call Service':
          return this.offerLawOnCall
        case 'Trademark and Legal Services':
          return await this.displayTrademarkAndLegalServices(item)
        default:
          return true
      }
    },
    async hideMonitoringService(item) {
      return (item.name === "Monitoring" && (this.monitoringServiceActive)) 
    },
    isTrial(item) {
      return item?.name === 'Law On Call Service' ?
        this.lawOnCallTrialEligible : false
    },
    trialText(item) {
      // TODO dynamically pull trial period durations.
      const itemName = item?.name
      switch (itemName) {
        case 'Law On Call Service': {
          return 'Free Trial - 60 Days'
        }
        default: {
          return 'Free Trial'
        }
      }
    },
    async displayTrademarkAndLegalServices(item) {
      const productCategoryIds = {
        companyId: this.company?.id,
        bucketId: item?.bucketId,
      }

      await this.getProductCategories(productCategoryIds)
      if (!this.productCategories.length) return false

      return !this.offerLawOnCall && this.productCategories.length <= 1 ?
        this.productCategories[0].name !== 'Law on Call Service' :
        true
    },
    async filterItems() {
      const processedItems = await Promise.all(this.items.map(async (item) => {
        const isHideMerchantServices = this.hideMerchantServices(item)
        const isHideLawOnCall = await this.hideLawOnCall(item)
        const hideMonitoring = await this.hideMonitoringService(item)

        if (hideMonitoring) {
          return  
        }

        if (isHideLawOnCall && isHideMerchantServices) {
          return item
        }
      }))

      return processedItems.filter(item => item !== undefined)
    },

  },
}
</script>

<style lang="scss" scoped>

.multiple-product-selection {
  @include ct-ui-selectable-horizontal;
  @include ct-ui-card-shadow;

  min-height: 30px;
  height: 100%;
  cursor: pointer;
  margin-bottom: 5px;
  box-shadow: none !important;

  .main {
    padding: 1.25em;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    column-gap: 0.4em;

    .name {
      font-size: 1.0em;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      > .badge-success {
        background: $ct-ui-color-11;
        font-size: 0.9em;
        margin-left: 0.5rem;
      }
    }

    .description {
      color: #777;
      font-size: small;
      margin-top: 0.25rem;
      margin-right: 4rem;
    }

    .choice {
      min-width: 85px;
      color: $ct-ui-primary; //TODO: Change arrow to circle in arrow from design team
      display: block;
    }
  }
}
</style>
